import * as React from "react"
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import BrandingDesignImage from '../../images/branding-design-3.jpg';
import BrandingDesignImage2 from '../../images/branding-design-4.jpg';
import Branding1 from '../../images/client-logos/clientlogo1Glossy.jpg';
import Branding2 from '../../images/client-logos/clientlogo2Glossy.jpg';
import Branding3 from '../../images/client-logos/clientlogo3Glossy.jpg';
import Branding4 from '../../images/client-logos/clientlogo4Glossy.jpg';
import Branding5 from '../../images/client-logos/clientlogo5Glossy.jpg';
import Branding6 from '../../images/client-logos/clientlogo6Glossy.jpg';
import SEO from '../../components/Seo';
import CTA from "../../components/CTA";


const BrandingDesign = () => {

    return(
        <Layout>
            <SEO title="Branding Design" description={"At HealthOne Digital, we create a strong brand idently for your healthcare practice"} keywords={"Healthcare Logo Design"}/>
            <section>
                <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <h1 className="mt-4 mb-5 text-5xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block text-blue-900">Brading Design</span> 
                                         <span className="block mt-0 lg:mt-2 text-blue-500">For Healthcare</span>
                                    </h1>
                                    <span className="text-base text-gray-800 sm:text-xl lg:text-lg xl:text-xl">
                                        <Link className="hover:text-white" to="/services">SERVICES</Link> 
                                        <span> / </span> 
                                        BRANDING DESIGN
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
            <section>
                <div className="relative bg-blue-50 py-8 lg:py-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12 sm:pt-0 lg:pt-12">
                                <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                                A strong brand is important to build patient trust and stand out in an increasingly competitive marketplace.
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-xl leading-8">
                                    The best way to stand out in a competitive healthcare marketplace and build trust with your patients, is to have professional branding that reflects your brand values.
                                    </p>
                                    <p className="text-xl leading-8">
                                    While this may not have been important a decade ago, branding is becoming an important factor in the performance of healthcare practices. Patients now have access to more information and choices than ever before and they expect an experience from their healthcare providers which makes them feel valued.
                                    </p>
                                    <p className="text-xl leading-8">
                                    A strong brand can help create this feeling of value, trust, loyalty and mindshare in patients but it expands beyond a new logo or slogan. The important questions that need to be asked revolve around your healthcare practice’s values, perceptions and the key demographics.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="relative mt-10 sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-72">
                                <div className="absolute inset-y-0 left-1/2 w-full bg-gray-50 rounded-r-3xl lg:left-72"></div>
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-48 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={BrandingDesignImage} alt="A computer screen showing various grahpic design elements"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* VIEW WORK SECTION */}
            <section>
                <div className="pb-16 bg-gray-50 lg:z-10 lg:relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
                    <div className="relative lg:-my-8">
                        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"></div>
                        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                        <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                            <img className="object-cover lg:h-full lg:w-full" src={BrandingDesignImage2} alt="Two people in a consultation while staring at laptops."/>
                        </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                        <blockquote>
                            <div>
                          
                            <p className="mt-6 text-3xl font-medium leading-10 text-blue-900">
                            We guide you through the process of discovering the true values that underlie your healthcare brand. Only then do we create design assets that reflects those values.
                            </p>
                            </div>
                         
                        </blockquote>
                        </div>
                    </div>
                    </div>
                </div>
            </section>


            {/* CTA */}
            <CTA/>
        </Layout>
    )
}

export default BrandingDesign;